import React from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();

  // Verificar si el usuario está logueado (existe un token)
  const isLoggedIn = !!localStorage.getItem('token');

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          flexDirection: { xs: 'column', sm: 'row' }, // Responsivo: columna en móvil, fila en escritorio
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2, // Espaciado entre botones
        }}
      >
        {isLoggedIn && (
          <>
            {/* Contenedor de los botones principales */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2, // Espaciado entre botones
                flexWrap: 'wrap', // Permitir que los botones se ajusten en varias líneas si es necesario
              }}
            >
              {/* Botón principal para "Inicio" */}
              <Button
                component={Link}
                to="/dashboard"
                variant="contained"
                color="primary"
                sx={{
                  textTransform: 'none', // Texto en mayúsculas desactivado
                  fontWeight: 'bold',
                }}
              >
                Inicio
              </Button>

              {/* Botón para "Venta de dólares" */}
              <Button
                component={Link}
                to="/dollar-sale"
                color="inherit"
              >
                Venta de dólares
              </Button>
            </Box>

            {/* Botón de "Cerrar Sesión" */}
            <Box
              sx={{
                mt: { xs: 2, sm: 0 }, // Añadir margen superior en pantallas pequeñas
              }}
            >
              <Button
                color="inherit"
                onClick={logout}
                sx={{
                  textTransform: 'none',
                }}
              >
                Cerrar Sesión
              </Button>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
