// src/pages/Dashboard.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Stack,
  Paper,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TransactionForm from '../components/TransactionForm';

function Dashboard() {
  const [transactions, setTransactions] = useState([]);
  const [balance, setBalance] = useState(0);
  const [editTransaction, setEditTransaction] = useState(null); // Manejar edición
  const [deleteTransactionId, setDeleteTransactionId] = useState(null); // Manejar eliminación
  const navigate = useNavigate();

  const fetchTransactions = async () => {
    try {
      const res = await axios.get('http://localhost:5000/api/transactions', {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      setTransactions(res.data);
      calculateBalance(res.data);
    } catch (err) {
      console.error(err.response?.data || err.message);
      if (err.response && err.response.status === 401) {
        navigate('/');
      }
    }
  };

  const calculateBalance = (transactions) => {
    const total = transactions.reduce((acc, tx) => {
      return tx.type === 'ingreso' ? acc + tx.amount : acc - tx.amount;
    }, 0);
    setBalance(total);
  };

  const confirmDeleteTransaction = (id) => {
    setDeleteTransactionId(id);
  };

  const handleDeleteTransaction = async () => {
    try {
      await axios.delete(`http://localhost:5000/api/transactions/${deleteTransactionId}`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      setDeleteTransactionId(null); // Cerrar diálogo de confirmación
      fetchTransactions(); // Actualizar transacciones
    } catch (err) {
      console.error(err.response?.data || err.message);
      alert('Hubo un error al eliminar la transacción.');
    }
  };

  const handleCancelDelete = () => {
    setDeleteTransactionId(null);
  };

  const openEditDialog = (transaction) => {
    setEditTransaction(transaction);
  };

  const handleEdit = async () => {
    try {
      await axios.put(
        `http://localhost:5000/api/transactions/${editTransaction._id}`,
        editTransaction,
        {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        }
      );
      setEditTransaction(null); // Cerrar diálogo de edición
      fetchTransactions(); // Actualizar transacciones
    } catch (err) {
      console.error(err.response?.data || err.message);
      alert('Hubo un error al editar la transacción.');
    }
  };

  const handleCancelEdit = () => {
    setEditTransaction(null);
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <Box maxWidth="lg" mx="auto" mt={10} px={{ xs: 2, sm: 3 }}>
      <Typography variant="h4" component="h1" mb={4}>
        Mis Finanzas
      </Typography>

      <TransactionForm fetchTransactions={fetchTransactions} />

      <Box mt={4} mb={4} p={2} borderRadius="8px" bgcolor="#f5f5f5">
        <Typography variant="h6">
          Fondos actuales:{' '}
          <Typography
            component="span"
            color={balance >= 0 ? 'success.main' : 'error.main'}
            fontWeight="bold"
          >
            ${balance.toFixed(2)}
          </Typography>
        </Typography>
      </Box>

      <Stack spacing={2}>
        {transactions.map((tx) => (
          <Paper key={tx._id} elevation={2} sx={{ p: 2 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              spacing={2}
            >
              <Typography variant="body2" color="text.secondary" sx={{ minWidth: '150px' }}>
                {new Date(tx.date).toLocaleString()}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', sm: 'block' } }} />
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {tx.description}
              </Typography>
              <Typography
                variant="body1"
                color={tx.type === 'ingreso' ? 'success.main' : 'error.main'}
                fontWeight="bold"
              >
                {tx.type === 'ingreso' ? '+' : '-'}${tx.amount.toFixed(2)}
              </Typography>
              <Stack direction="row" spacing={1} mt={{ xs: 2, sm: 0 }}>
                <Button size="small" variant="contained" color="primary" onClick={() => openEditDialog(tx)}>
                  Editar
                </Button>
                <Button size="small" variant="contained" color="error" onClick={() => confirmDeleteTransaction(tx._id)}>
                  Eliminar
                </Button>
              </Stack>
            </Stack>
          </Paper>
        ))}
      </Stack>

      {/* Diálogo de Confirmación para Eliminar */}
      <Dialog open={Boolean(deleteTransactionId)} onClose={handleCancelDelete}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que deseas eliminar esta transacción? Esta acción no se puede deshacer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancelar</Button>
          <Button onClick={handleDeleteTransaction} variant="contained" color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de Edición */}
      {editTransaction && (
        <Dialog open={Boolean(editTransaction)} onClose={handleCancelEdit}>
          <DialogTitle>Editar Transacción</DialogTitle>
          <DialogContent>
            <Stack spacing={2} mt={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="type-label">Tipo *</InputLabel>
                <Select
                  labelId="type-label"
                  name="type"
                  value={editTransaction.type}
                  onChange={(e) =>
                    setEditTransaction({ ...editTransaction, type: e.target.value })
                  }
                  label="Tipo *"
                >
                  <MenuItem value="ingreso">Ingreso</MenuItem>
                  <MenuItem value="gasto">Gasto</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Monto"
                name="amount"
                type="number"
                value={editTransaction.amount}
                onChange={(e) =>
                  setEditTransaction({ ...editTransaction, amount: parseFloat(e.target.value) })
                }
              />
              <TextField
                label="Descripción"
                name="description"
                value={editTransaction.description}
                onChange={(e) =>
                  setEditTransaction({ ...editTransaction, description: e.target.value })
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelEdit}>Cancelar</Button>
            <Button onClick={handleEdit} variant="contained" color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default Dashboard;
