// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme'; // Asegúrate de crear este archivo
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import DollarSale from './pages/DollarSale';
import Navbar from './components/Navbar'; // Si deseas incluir la barra de navegación en todas las páginas

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Normaliza los estilos entre navegadores */}
      <Router>
        <Navbar /> {/* Incluye la barra de navegación */}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dollar-sale" element={<DollarSale />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
