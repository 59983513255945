import React from 'react';

function DollarSale() {
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Venta de Dólares</h1>
      <p>Página en construcción</p>
    </div>
  );
}

export default DollarSale;
