// src/pages/Login.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link as MuiLink,
  Stack,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

function Login() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState(null); // Manejo de errores
  const { username, password } = formData;
  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null); // Limpiar error al escribir
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validar campos obligatorios en el cliente
    if (!username || !password) {
      setError('Por favor, completa ambos campos');
      return;
    }

    try {
      const res = await axios.post('http://localhost:5000/api/auth/login', {
        username,
        password,
      });

      localStorage.setItem('token', res.data.token);
      navigate('/dashboard');
    } catch (err) {
      console.error('Error al iniciar sesión:', err.response?.data || err.message);
      setError(err.response?.data?.msg || 'Error al iniciar sesión. Inténtalo nuevamente.');
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: 'auto',
        marginTop: 10,
        padding: 4,
        border: '1px solid #ccc',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Iniciar Sesión
      </Typography>
      <form onSubmit={onSubmit}>
        <Stack spacing={2}>
          {/* Campo de Usuario */}
          <TextField
            label="Usuario"
            name="username"
            value={username}
            onChange={onChange}
            required
            fullWidth
          />
          {/* Campo de Contraseña */}
          <TextField
            label="Contraseña"
            name="password"
            type="password"
            value={password}
            onChange={onChange}
            required
            fullWidth
          />
          {/* Mensaje de Error Debajo de la Contraseña */}
          {error && (
            <Alert severity="error" sx={{ mt: -1 }}>
              {error}
            </Alert>
          )}
          {/* Botón de Ingreso */}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Ingresar
          </Button>
          {/* Enlace para Registrarse */}
          <Typography variant="body2" align="center">
            ¿No tienes una cuenta?{' '}
            <MuiLink component={Link} to="/register" color="primary">
              Regístrate
            </MuiLink>
          </Typography>
        </Stack>
      </form>
    </Box>
  );
}

export default Login;
