import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stack,
  Paper,
} from '@mui/material';
import axios from 'axios';

function TransactionForm({ fetchTransactions }) {
  const [formData, setFormData] = useState({
    type: 'ingreso',
    amount: '',
    description: '',
  });

  const { type, amount, description } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validación simple
    if (amount <= 0) {
      alert('El monto debe ser mayor que cero.');
      return;
    }

    // Si no hay descripción, asignar "Sin detalle"
    const finalDescription = description.trim() === '' ? 'Sin detalle' : description;

    try {
      await axios.post(
        'http://localhost:5000/api/transactions',
        {
          type,
          amount: parseFloat(amount),
          description: finalDescription,
          date: new Date().toISOString(),
        },
        {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        }
      );

      fetchTransactions();
      setFormData({ type: 'ingreso', amount: '', description: '' });
    } catch (err) {
      console.error(err.response?.data || err.message);
      alert('Hubo un error al agregar la transacción. Por favor, intenta nuevamente.');
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        maxWidth: 600,
        mx: 'auto',
        width: '100%', // Ajuste responsive
        mt: { xs: 2, sm: 4 }, // Márgenes adaptables
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
        Agregar Transacción
      </Typography>
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          {/* Tipo de Transacción */}
          <FormControl fullWidth variant="outlined">
            <InputLabel id="type-label">Tipo *</InputLabel>
            <Select
              labelId="type-label"
              name="type"
              value={type}
              onChange={onChange}
              label="Tipo *" // Asegura que el label se asocie correctamente
            >
              <MenuItem value="ingreso">Ingreso</MenuItem>
              <MenuItem value="gasto">Gasto</MenuItem>
            </Select>
          </FormControl>

          {/* Monto */}
          <TextField
            label="Monto"
            name="amount"
            type="number"
            value={amount}
            onChange={onChange}
            required
            fullWidth
            InputProps={{
              inputProps: {
                min: 0,
                step: '0.01',
              },
            }}
            helperText="Ingrese el monto en pesos."
          />

          {/* Descripción */}
          <TextField
            label="Descripción"
            name="description"
            type="text"
            value={description}
            onChange={onChange}
            fullWidth
            helperText="Opcional: Añade una descripción para la transacción."
          />

          {/* Botón de Envío */}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Agregar Transacción
          </Button>
        </Stack>
      </form>
    </Paper>
  );
}

export default TransactionForm;
